import { Component, OnDestroy, OnInit } from "@angular/core";
import { IncidentTrackerService } from "src/app/shared/services/incident-tracker.service";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { AssessmentCentralService } from "src/app/shared/services/assessment-central.service";
import { FilterService } from "src/app/shared/services/filter.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-list-tracker",
  templateUrl: "./list-tracker.component.html",
  providers: [DatePipe],
  styleUrls: ["./list-tracker.component.scss"],
})
export class ListTrackerComponent implements OnInit, OnDestroy {
  staticText: any = (textConfiguration as any).default;
  incidentList: any;
  auditInsights: any;
  incidentTrackerForm: FormGroup | any;
  vendorList: any;
  programList: any;
  projectList: any;
  portfolioList: any = [];
  subportfolioList: any = [];
  ownersList: any = [];
  auditList: any;
  domainList: any;
  subDomainList: any;
  subDomainSelectedList: any;
  teamList: any;
  auditLogList: any;
  deleteIncidentId: any;
  editIncidentId: any;
  incidentType: any;
  incidentValue: any = "New Incident";
  selectedTabIndex: number = 0;
  filterType: any = null;
  filterName: any = null;
  filterStatus: any = null;
  filterPortfolio: any = null;
  filterSubportfolio: any = null;
  filterProgram: any = null;
  filterProject: any = null;
  filterVendor: any = null;
  filterTitle: any = null;
  auditInstances: any;
  incidentStatusList: any = [];
  incidentSeverityList: any = [];
  subscription: any;
  filterObj: any;
  tagFilterId: any;
  auditInstanceTitleList: any = [];
  dataLoaded: boolean = false;

  constructor(
    private IncidentTrackerService: IncidentTrackerService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private datepipe: DatePipe,
    private toastrService: ToastrService,
    private assessmentCentralService: AssessmentCentralService,
    private filterService: FilterService,
    private router: Router
  ) {
    this.incidentTrackerForm = this.fb.group({
      title: ["", Validators.required],
      description: ["", Validators.required],
      vendor_id: ["", Validators.required],
      project_id: ["", Validators.required],
      program_id: ["", Validators.required],
      type_id: ["", Validators.required],
      audit_instance_id: [""],
      severity_id: ["", Validators.required],
      domain_id: ["", Validators.required],
      subdomain_id: ["", Validators.required],
      impact: ["", Validators.required],
      source: ["", Validators.required],
      reported_date: ["", Validators.required],
      raised_by: ["", Validators.required],
      corrective_plan: ["", Validators.required],
      preventive_plan: ["", Validators.required],
      primary_owner: ["", Validators.required],
      secondary_owner: ["", Validators.required],
      status_id: ["", Validators.required],
      closure_date: ["", Validators.required],
      comment: ["", Validators.required],
      portfolio_id: ["", Validators.required],
      sub_portfolio_id: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.getIncidentList();
    this.getVendor();
    this.getProgram();
    this.getProject();
    this.getDomain();
    this.getSubDomain();
    this.getUserList();
    this.getAuditLogList();
    this.getIncidentType();
    this.getOwners();
    this.getPortfolioList();
    this.getSubportfolioList();
    this.getAuditInstances();
    this.getIncidentStatus();
    this.getIncidentSeverity();
    this.getAuditInsights();
    this.subscription = this.filterService.filterSubject.subscribe(
      (res: any) => {
        this.filterObj = res;
        this.tagFilterId = [];
        Object.keys(this.filterObj).forEach((element: any) => {
          if (element == "tag") {
            console.log(this.filterObj[element]);
            this.filterObj[element]?.array?.forEach((tag: any) => {
              this.tagFilterId.push(tag?.id);
            });
          }
        });
        this.search();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getAuditInsights() {
    this.IncidentTrackerService.getAuditInsights().subscribe((resp: any) => {
      this.auditInsights = resp;
    });
  }

  getIncidentStatus() {
    this.IncidentTrackerService.getIncidentStatus().subscribe((resp: any) => {
      this.incidentStatusList = resp;
    });
  }

  getIncidentSeverity() {
    this.IncidentTrackerService.getIncidentSeverity().subscribe((resp: any) => {
      this.incidentSeverityList = resp;
    });
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  prepareInstancesList() {
    this.auditInstances.forEach((category: any) => {
      category.instances.forEach((instance: any) => {
        this.auditInstanceTitleList.push({
          value: instance.template.id,
          display_name: instance.template.title,
        });
      });
    });
  }

  getAuditInstances() {
    this.dataLoaded = false;
    this.IncidentTrackerService.getAuditInstances().subscribe((resp: any) => {
      this.auditInstances = resp;
      this.prepareInstancesList();
      this.auditInstances.forEach((category: any) => {
        category.instances.forEach((element: any) => {
          (element.color = this.getGMapRandomColor()),
            (element.last_updated_on_dt = new Date(element.last_updated_on)),
            (element.owners = []),
            element?.user?.forEach((e: any) => {
              element.owners.push({
                user: e,
              });
            });
        });
      });
      this.dataLoaded = true;
    });
  }

  getOwners() {
    this.assessmentCentralService.getOwnersList().subscribe((resp) => {
      this.ownersList = resp;
    });
  }

  getPortfolioList() {
    this.filterService.getPortfolios().subscribe((resp: any) => {
      this.portfolioList = resp.records;
    });
  }

  getSubportfolioList() {
    this.filterService
      .getSubPortfolios(this.filterPortfolio)
      .subscribe((resp) => {
        this.subportfolioList = resp;
      });
  }

  async getIncidentList() {
    return await this.IncidentTrackerService.getIncidentTrackerList().subscribe(
      (res: any) => {
        this.incidentList = res;
      }
    );
  }

  onClickNewIncident(modal: any) {
    this.editIncidentId = "";
    this.incidentTrackerForm.reset();
    this.incidentValue = "New Incident";
    this.dialog.open(modal, {
      maxHeight: "70vh",
    });
  }

  async getVendor() {
    return await this.IncidentTrackerService.getVendor().subscribe(
      (res: any) => {
        this.vendorList = res;
      }
    );
  }
  async getProgram() {
    return await this.IncidentTrackerService.getProgram().subscribe(
      (res: any) => {
        this.programList = res;
      }
    );
  }
  async getProject() {
    return await this.IncidentTrackerService.getProject().subscribe(
      (res: any) => {
        this.projectList = res;
      }
    );
  }

  async getDomain() {
    return await this.IncidentTrackerService.getDomain().subscribe(
      (res: any) => {
        this.domainList = res;
      }
    );
  }
  async getSubDomain() {
    return await this.IncidentTrackerService.getSubDomain().subscribe(
      (res: any) => {
        this.subDomainList = res;
      }
    );
  }
  async getAuditLogList() {
    return await this.IncidentTrackerService.getAuditLogList().subscribe(
      (res: any) => {
        this.auditLogList = res;
      }
    );
  }

  async getUserList() {
    return await this.IncidentTrackerService.getUser().subscribe((res: any) => {
      this.teamList = res;
    });
  }
  async getIncidentType() {
    return await this.IncidentTrackerService.getIncidentType().subscribe(
      (res: any) => {
        this.incidentType = res;
      }
    );
  }

  cancel() {
    this.dialog.closeAll();
    this.incidentValue = "New Incident";
  }

  saveTracker(type: any) {
    if (this.incidentTrackerForm.invalid) {
      this.incidentTrackerForm.markAllAsTouched();
      // this.toastr.error("Please fill mandatory fields with valid input");
      return;
    }
    this.spinner.show();
    this.incidentTrackerForm.value.reported_date = this.datepipe.transform(
      this.incidentTrackerForm.get("reported_date").value,
      "yyyy-MM-dd"
    );
    this.incidentTrackerForm.value.closure_date = this.datepipe.transform(
      this.incidentTrackerForm.get("closure_date").value,
      "yyyy-MM-dd"
    );

    this.IncidentTrackerService.addNewIncidentTracker(
      this.incidentTrackerForm.value
    ).subscribe((res: any) => {
      this.spinner.hide();
      this.incidentList.push(res);
      this.incidentTrackerForm.reset();
      this.dialog.closeAll();
    });
  }

  deleteIncident(i: any, content: any) {
    this.deleteIncidentId = i;
    this.dialog.open(content);
  }

  deleteDialog() {
    this.spinner.show();
    this.IncidentTrackerService.deleteIncidentTracker(
      this.deleteIncidentId
    ).subscribe(
      (res: any) => {
        this.toastrService.success("Incident deleted successfully");
        this.getIncidentList();
        this.dialog.closeAll();
        this.spinner.hide();
      },
      (error) => {
        throw error;
      }
    );
  }

  editIncident(inc: any, modal: any) {
    this.dialog.open(modal, {
      maxHeight: "70vh",
    });
    this.editIncidentId = inc.id;
    let event = { value: inc?.domain?.id };
    this.onSelectionChange(event);
    this.incidentValue = "Incident Details";
    this.incidentTrackerForm.patchValue({
      title: inc?.title,
      description: inc?.description,
      vendor_id: inc?.vendor?.id,
      project_id: inc?.project?.id,
      program_id: inc?.program?.id,
      type_id: inc?.type?.id,
      audit_instance_id: inc?.audit_instance?.id,
      severity_id: inc?.severity?.id,
      domain_id: inc?.domain?.id,
      subdomain_id: inc?.subdomain?.id,
      impact: inc?.impact,
      source: inc?.source,
      reported_date: inc?.reported_date,
      raised_by: inc?.raised_by,
      corrective_plan: inc?.corrective_plan,
      preventive_plan: inc?.preventive_plan,
      primary_owner: inc?.primary_owner,
      secondary_owner: inc?.secondary_owner,
      status_id: inc?.status?.id,
      closure_date: inc?.closure_date,
      comment: inc?.comment,
      portfolio_id: "" + inc?.portfolio?.id,
      sub_portfolio_id: "" + inc?.sub_portfolio?.id,
    });
  }

  updateTracker() {
    if (this.incidentTrackerForm.invalid) {
      this.incidentTrackerForm.markAllAsTouched();
      // this.toastr.error("Please fill mandatory fields with valid input");
      return;
    }
    this.spinner.show();
    this.incidentTrackerForm.value.reported_date = this.datepipe.transform(
      this.incidentTrackerForm.get("reported_date").value,
      "yyyy-MM-dd"
    );
    this.incidentTrackerForm.value.closure_date = this.datepipe.transform(
      this.incidentTrackerForm.get("closure_date").value,
      "yyyy-MM-dd"
    );

    this.IncidentTrackerService.updateIncidentTracker(
      this.editIncidentId,
      this.incidentTrackerForm.value
    ).subscribe((res: any) => {
      this.spinner.hide();
      this.getIncidentList();
      this.incidentTrackerForm.reset();
      this.dialog.closeAll();
    });
  }

  onSelectionChange(event: any) {
    let domain = this.domainList.find((item: any) => item.id === event.value);
    this.subDomainSelectedList = this.subDomainList.filter(
      (item: any) => item.incident_domain === domain.uuid
    );
  }

  tabChanged(event: any) {
    this.selectedTabIndex = event.index;
  }

  search() {
    const type = this.filterType ? this.filterType : "";
    const name = this.filterName ? this.filterName : "";
    const status = this.filterStatus ? this.filterStatus : "";
    const portfolio = this.filterPortfolio ? this.filterPortfolio : "";
    const subPortfolio = this.filterSubportfolio ? this.filterSubportfolio : "";
    const program = this.filterProgram ? this.filterProgram : "";
    const project = this.filterProject ? this.filterProject : "";
    const vendor = this.filterVendor ? this.filterVendor : "";
    const tags = this.tagFilterId ? this.tagFilterId.join("|") : "";
    const title = this.filterTitle ? this.filterTitle : "";

    if (this.selectedTabIndex === 0) {
      this.IncidentTrackerService.getFilteredAuditInstances(
        type,
        name,
        status,
        portfolio,
        subPortfolio,
        program,
        project,
        vendor,
        tags
      ).subscribe((resp: any) => {
        this.auditInstances = resp;
        this.auditInstances.forEach((category: any) => {
          category.instances.forEach((element: any) => {
            (element.color = this.getGMapRandomColor()),
              (element.last_updated_on_dt = new Date(element.last_updated_on)),
              (element.owners = []),
              element?.user?.forEach((e: any) => {
                element.owners.push({
                  user: e,
                });
              });
          });
        });
      });
    } else {
      this.IncidentTrackerService.getFilteredIncidents(
        title,
        portfolio,
        subPortfolio,
        program,
        project,
        vendor
      ).subscribe((resp: any) => {
        this.incidentList = resp;
      });
    }
  }

  navigate(readMode: any = 0) {
    this.router.navigateByUrl(`incidentracker/templates?readMode=${readMode}`);
  }

  openInstance(instance: any) {
    this.router.navigateByUrl(`incidentracker/instance?id=${instance.id}`);
  }
}
