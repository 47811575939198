import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { IncidentTrackerService } from "src/app/shared/services/incident-tracker.service";

@Component({
  selector: "app-edit-instance-dialog",
  templateUrl: "./edit-instance-dialog.component.html",
  styleUrls: ["./edit-instance-dialog.component.scss"],
  providers: [DatePipe],
})
export class EditInstanceDialogComponent implements OnInit {
  instanceInfo: any;
  responseInfo: any;
  ownersList: any = [];
  file: any;
  fileName: any;
  instanceForm = new FormGroup({
    owner: new FormControl("", [Validators.required]),
    due_date: new FormControl("", [Validators.required]),
    status: new FormControl("", [Validators.required]),
    comment: new FormControl("", [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<EditInstanceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private incidentTrackerService: IncidentTrackerService,
    private toastrService: ToastrService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.instanceInfo = this.data.instanceInfo;
    this.responseInfo = this.data.response;
    this.initializeValues();
    this.getOwnersList();
  }

  getOwnersList() {
    this.incidentTrackerService.getOwner().subscribe((resp) => {
      this.ownersList = resp;
    });
  }

  initializeValues() {
    this.instanceForm.patchValue({
      owner: this.responseInfo.owner__id,
      due_date: this.responseInfo.due_date,
      status: this.responseInfo.status,
      comment: this.responseInfo.comment,
    });
  }

  uploadEvidence() {
    let formData = new FormData();
    formData.append("file", this.file);
    formData.append("file_name", this.file.name);
    this.incidentTrackerService
      .uploadEvidence(this.responseInfo.id, formData)
      .subscribe((resp: any) => {});
  }

  editInstance() {
    if (this.file) {
      this.uploadEvidence();
    }
    this.instanceForm.value.due_date = this.datePipe.transform(
      this.instanceForm.value.due_date,
      "yyyy-MM-dd"
    );
    this.incidentTrackerService
      .editInstanceResponse(this.responseInfo.id, this.instanceForm.value)
      .subscribe((resp: any) => {
        this.dialogRef.close("saved");
        this.toastrService.success("Instance edited succesfully");
      });
  }

  onFileChange(event: any) {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    event.target.value = "";
  }
}
