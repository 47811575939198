import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IncidentTrackerService } from "src/app/shared/services/incident-tracker.service";

@Component({
  selector: "template-create-modal",
  templateUrl: "template-create-modal.html",
  styleUrls: ["template-create-modal.scss"],
})
export class TemplateCreateModalDialogComponent {
  categories: any;
  frequencies: any;
  auditTypes: any;
  assessmentTags: any;
  usersList: any;
  templateForm = new FormGroup({
    title: new FormControl(""),
    description: new FormControl("", [Validators.required]),
    applicability: new FormControl(""),
    focus_area: new FormControl(""),
    internal_assessor: new FormControl(""),
    external_assessor: new FormControl(""),
    is_external_assessment: new FormControl(false),
    category: new FormControl("", [Validators.required]),
    frequency: new FormControl("", [Validators.required]),
    type: new FormControl("", [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<TemplateCreateModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private incidentTrackerService: IncidentTrackerService
  ) {}

  ngOnInit() {
    this.getCategories();
    this.getFrequencies();
    this.getAuditTypes();
    this.getUsers();
  }

  getUsers() {
    this.incidentTrackerService.getUser().subscribe((resp: any) => {
      this.usersList = resp;
    });
  }

  getAuditTypes() {
    this.incidentTrackerService.getAuditTypes().subscribe((response) => {
      this.auditTypes = response;
    });
  }

  getFrequencies() {
    this.incidentTrackerService.getFrequencies().subscribe((response) => {
      this.frequencies = response;
    });
  }

  getCategories() {
    this.incidentTrackerService
      .getAuditTemplateCategory()
      .subscribe((response) => {
        this.categories = response;
      });
  }

  getChildTags(data: any) {
    this.assessmentTags = data;
  }

  createAssessment() {
    let dialogData = this.templateForm.value;
    dialogData["selectedTags"] = this.assessmentTags;
    this.dialogRef.close(dialogData);
  }

  closeModal(id: any): void {
    this.dialogRef.close(id);
  }
}
