<app-modal-header-common title="Create Audit Template">
</app-modal-header-common>
<div class="plan-form">
  <p class="uploaded-file">Template file - {{this.data.uploadedFile.name}}</p>
  <form [formGroup]="templateForm">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Category</mat-label>
      <mat-select formControlName="category">
        <mat-option *ngFor="let option of categories" [value]="option.id">
          {{option.display_name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Frequency</mat-label>
      <mat-select formControlName="frequency">
        <mat-option *ngFor="let option of frequencies" [value]="option.id">
          {{option.display_name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let option of auditTypes" [value]="option.id">
          {{option.display_name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Applicability</mat-label>
      <input matInput formControlName="applicability" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Focus Area</mat-label>
      <input matInput formControlName="focus_area" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Internal assessor</mat-label>
      <mat-select formControlName="internal_assessor">
        <mat-option *ngFor="let option of usersList" [value]="option.id">
          {{option.first_name + ' ' + option.last_name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle class="mb-3" formControlName="is_external_assessment"
      >Is external assessment</mat-slide-toggle
    >
    <mat-form-field
      class="w-100"
      appearance="outline"
      *ngIf="templateForm.get('is_external_assessment')?.value"
    >
      <mat-label>External assessor</mat-label>
      <input matInput formControlName="external_assessor" />
    </mat-form-field>
    <app-tag
      [noIds]="true"
      [tagsIds]="assessmentTags"
      (childTags)="getChildTags($event)"
    ></app-tag>
    <div style="display: flex; justify-content: end" class="mt-3">
      <button
        mat-raised-button
        color="primary"
        class="templ-btn"
        (click)="createAssessment()"
        [disabled]="templateForm.invalid"
      >
        Create
      </button>
    </div>
  </form>
</div>
