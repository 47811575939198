import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IncidentTrackerService } from "src/app/shared/services/incident-tracker.service";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import * as Highcharts from "highcharts";
import { MatDialog } from "@angular/material/dialog";
import { EditInstanceDialogComponent } from "../edit-instance-dialog/edit-instance-dialog.component";
const noData = require("highcharts/modules/no-data-to-display");
noData(Highcharts);
@Component({
  selector: "app-audit-instance",
  templateUrl: "./audit-instance.component.html",
  styleUrls: ["./audit-instance.component.scss"],
})
export class AuditInstanceComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  staticText: any = (textConfiguration as any).default;
  instanceId: any;
  instanceInfo: any;
  auditInfo: any;
  respondent: any;
  auditor: any;
  chartData: any;
  instanceTags: any;
  isEmbedded: any = false;
  tableColumns: any = [
    "Finding/Observation",
    "Severity",
    "Impact",
    "Remediation",
    "Comment",
    "Owner",
    "Evidence",
    "Due Date",
    "Status",
    "Action",
  ];
  constructor(
    private incidentTrackerService: IncidentTrackerService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.instanceId = params.get("id");
      this.isEmbedded = params.get("embedded") == "true";
      this.getInstanceInfo();
    });
  }

  initializeAuditInfo() {
    this.auditInfo = "";
    if (this.instanceInfo.portfolio?.display_name) {
      this.auditInfo += this.instanceInfo.portfolio.display_name + " | ";
    }
    if (this.instanceInfo.sub_portfolio?.display_name) {
      this.auditInfo += this.instanceInfo.sub_portfolio.display_name + " | ";
    }
    if (this.instanceInfo.program?.name) {
      this.auditInfo += this.instanceInfo.program.name + " | ";
    }
    if (this.instanceInfo.project?.project_name) {
      this.auditInfo += this.instanceInfo.project.project_name + " | ";
    }
    if (this.instanceInfo.vendor?.name) {
      this.auditInfo += this.instanceInfo.vendor.name;
    }
    this.auditInfo = this.auditInfo.trimEnd();
    if (this.auditInfo.endsWith("|")) {
      this.auditInfo = this.auditInfo.slice(0, -1);
    }
  }

  getInstanceInfo() {
    this.incidentTrackerService
      .getInstanceInfo(this.instanceId)
      .subscribe((resp: any) => {
        this.instanceInfo = resp;
        this.respondent = resp.respondent;
        this.auditor = resp.auditor;
        this.initializeAuditInfo();
        this.generateGraphData();
        this.instanceTags = this.instanceInfo.template.tag_ids;
      });
  }

  generateGraphData() {
    const xAxis = Object.keys(this.instanceInfo.analytics);
    const yAxis = Object.values(this.instanceInfo.analytics);
    this.chartData = {
      chart: { type: "category", heigth: 180 },
      credits: { enabled: false },
      dataLabels: { enabled: true },
      exporting: { enabled: false },
      legend: { enabled: true },
      series: [
        {
          data: yAxis,
          dataLabels: { enabled: true },
          name: "Responses",
          type: "column",
        },
      ],
      title: { text: "" },
      tooltip: { shared: true },
      xAxis: {
        categories: xAxis,
        scrollbar: { enabled: false },
      },
      yAxis: { title: false },
      display_text: "Respondent response summary",
      id: this.instanceInfo.id,
      question_type: "matrix_grid",
    };
  }

  showEditDialog(response: any) {
    const dialog = this.dialog.open(EditInstanceDialogComponent, {
      width: "500px",
      data: {
        instanceInfo: this.instanceInfo,
        response: response,
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.getInstanceInfo();
      }
    });
  }

  downloadEvidence(instanceId: any, evidenceMetadata: any) {
    this.incidentTrackerService.downloadEvidence(instanceId).subscribe(
      (res: any) => {
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(res);
        const fileName = evidenceMetadata[0];
        downloadLink.setAttribute("download", fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
      (error: any) => {
        throw error;
      }
    );
  }
}
