import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { IncidentTrackerRoutingModule } from "./incident-tracker-routing.module";
import { ListTrackerComponent } from "./list-tracker/list-tracker.component";

import { SharedModule } from "src/app/shared/shared.module";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatMenuModule, MatMenuTrigger } from "@angular/material/menu";
import { MatOptionModule } from "@angular/material/core";
import { MaterialmodulesModule } from "src/app/shared/materialmodules.module";
import { MatInputModule } from "@angular/material/input";
import { AuditComponent } from "./audit/audit.component";
import { AuditLogComponent } from "./audit-log/audit-log.component";
import {
  AssessmentPreviewModalDialogComponent,
  IncidentTrackerTemplatesComponent,
} from "./incident-tracker-templates/incident-tracker-templates.component";
import { TemplateCreateModalDialogComponent } from "./template-create-modal/template-create-modal";
import { IncidentTrackerAssessmentCreateModalDialogComponent } from "./incident-tracker-templates/incident-tracker-assessment-create-modal/incident-tracker-assessment-create-modal";
import { AuditInstanceComponent } from "./audit-instance/audit-instance.component";
import { HighchartsChartModule } from "highcharts-angular";
import { ChartModule } from "angular-highcharts";
import { EditInstanceDialogComponent } from './edit-instance-dialog/edit-instance-dialog.component';

@NgModule({
  declarations: [
    ListTrackerComponent,
    AuditComponent,
    AuditLogComponent,
    IncidentTrackerTemplatesComponent,
    TemplateCreateModalDialogComponent,
    AssessmentPreviewModalDialogComponent,
    IncidentTrackerAssessmentCreateModalDialogComponent,
    AuditInstanceComponent,
    EditInstanceDialogComponent,
  ],
  imports: [
    CommonModule,
    IncidentTrackerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatIconModule,
    MaterialmodulesModule,
    MatButtonModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatMenuModule,
    MatOptionModule,
    ChartModule,
    HighchartsChartModule,
  ],
})
export class IncidentTrackerModule {}
