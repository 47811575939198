import { Component, OnInit } from '@angular/core';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { IncidentTrackerService } from 'src/app/shared/services/incident-tracker.service';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  providers: [DatePipe],
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  auditList:any;
  auditMasterForm: FormGroup | any;
  vendorList:any;
  templateSize: any;
  templatedoc:any;
  templateFile:any;
  fileData:any;
  deleteAuditId:any;
  constructor(
    private IncidentTrackerService :IncidentTrackerService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private datepipe: DatePipe,
  ) {
    this.auditMasterForm = this.fb.group({
      title: ['', Validators.required],
      description:['',Validators.required],
      vendor: ['', Validators.required],
      frequency:['',Validators.required],
      last_conducted: ['', Validators.required],
      next_due:['',Validators.required],
      template: [null, Validators.required],
      focus_area:['',Validators.required],
      agency: ['', Validators.required],
    });
   }

  ngOnInit(): void {
    this.getAuditList();
    this.getVendor();
  }

  async getAuditList(){
    return await this.IncidentTrackerService.getAuditList().subscribe((res: any) => {
      this.auditList = res;
    });
  }

  async getVendor(){
    return await this.IncidentTrackerService.getVendor().subscribe((res: any)=>{
      this.vendorList = res;
    })
  }

  onClickNewAuditMaster(modal:any){
    this.dialog.open(modal, {
      maxHeight: '70vh',
    });
  }

  onFileSelected(e:any){
    const inputElement = e.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      if (inputElement.files[0].size > 5000000) {
        this.toastrService.error('Please upload less than 5MB');
        return;
      }
      this.auditMasterForm.get('template').setValue(file);
      this.templateFile = inputElement.files[0].name;
      this.templateSize= inputElement.files[0].size / 1000000;
      const ext = inputElement.files[0].name.substring(inputElement.files[0].name.lastIndexOf('.') + 1)
      this.templatedoc = ext?.toLowerCase()
    }
  }
  deleteFile(i: any, content: any) {
    this.templatedoc = '';
    this.templateSize = '';
    this.templateFile = '';
    this.auditMasterForm.get('template').setValue('');
  }

  emptyFile() {
    this.templatedoc = '';
    this.templateSize = '';
    this.templateFile = '';
    this.auditMasterForm.get('template').setValue('');
  }
  saveAudit(type:any){
    this.auditMasterForm.markAllAsTouched();
    if (this.auditMasterForm.invalid ) {
       return;
    }
    this.auditMasterForm.value.last_conducted = this.datepipe.transform(this.auditMasterForm.value.last_conducted,"yyyy-MM-dd");
    this.auditMasterForm.value.next_due = this.datepipe.transform(this.auditMasterForm.value.next_due,"yyyy-MM-dd");
    const formData = new FormData();
    formData.append('file', this.auditMasterForm.get('template').value);
    formData.append('title', this.auditMasterForm.get('title').value);
    formData.append('vendor_id', this.auditMasterForm.get('vendor').value);
    formData.append('frequency', this.auditMasterForm.get('frequency').value);
    formData.append('last_conducted', this.auditMasterForm.value.last_conducted);
    formData.append('next_due', this.auditMasterForm.value.next_due);
    formData.append('focus_area', this.auditMasterForm.get('focus_area').value);
    formData.append('agency', this.auditMasterForm.get('agency').value);
    formData.append('description',this.auditMasterForm.get('description').value);
    this.spinner.show();
    this.IncidentTrackerService.postAuditMaster(formData).subscribe((res: any) => {
      this.toastrService.success('Audit created successfully');
      this.auditList = [];
      this.auditList = res;
      this.dialog.closeAll();
      this.emptyFile();
      this.auditMasterForm.reset();
      this.spinner.hide();
    });

  }

  cancel(){
    this.dialog.closeAll();
  }

  downloadTemplate(id:any,fileName:any){
    this.spinner.show();
    this.IncidentTrackerService.downloadFile(id).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }

  deleteAuditMaster(i: any, content: any) {
    this.deleteAuditId = i;
    this.dialog.open(content)
  }

  deleteDialog() {
    this.spinner.show();
    this.IncidentTrackerService.deleteAuditById(this.deleteAuditId).subscribe((res: any) => {
      this.toastrService.success('Audit deleted successfully');
      this.getAuditList();
      this.dialog.closeAll();
      this.spinner.hide();
    }, error => {
      throw error;
    })
  }
  
}
