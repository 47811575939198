<div class="w-100 bg-lavendar mt-80">
  <app-header></app-header>
</div>
<div class="bg-color p-3 pt-0">
  <div class="px-0 py-3">
    <app-bread-crumb></app-bread-crumb>
  </div>
  <div class="container-fluid pr-0 pl-0">
    <div class="row">
      <div class="col-sm-2">
        <div class="single-left-pane">
          <app-side-menu></app-side-menu>
        </div>
      </div>
      <div class="col-sm-7">
        <div class="single-center-pane">
          <div
            class="d-flex mb-2"
            style="align-items: center; justify-content: space-between"
          >
            <h5>Audit</h5>
            <div class="justify-content-end">
              <button
                mat-button
                class="bg-primary text-white mr-2"
                mat-button
                mat-flat-button
                [routerLink]="['audit-log']"
              >
                Audit Log
              </button>
              <button
                mat-button
                class="bg-primary text-white"
                mat-button
                mat-flat-button
                (click)="onClickNewAuditMaster(newAuditMasterPopUp)"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="material-icons-filled "
                  class="cursor-pointer fs-5"
                  >add
                </mat-icon>
                New Audit
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="custom-height">
                <div
                  class="custom-table table-responsive"
                  style="max-height: calc(100vh - 220px)"
                >
                  <table class="table table-bordered">
                    <thead style="position: sticky; z-index: 1">
                      <tr>
                        <th
                          *ngFor="
                            let field of staticText.incident_tracker
                              .table_header_audit
                          "
                          scope="col"
                          class="text-center"
                        >
                          {{ field }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let audit of auditList; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ audit?.title }}</td>
                        <td>{{ audit?.description }}</td>
                        <td>{{ audit?.vendor?.name }}</td>
                        <td>{{ audit?.agency }}</td>
                        <td>{{ audit?.focus_area }}</td>
                        <td>{{ audit?.frequency }}</td>
                        <td>{{ audit?.last_conducted }}</td>
                        <td>{{ audit?.next_due }}</td>
                        <td>
                          <div
                            style="color: blue; cursor: pointer"
                            (click)="
                              downloadTemplate(audit?.id, audit?.template)
                            "
                          >
                            {{ audit?.template }}
                          </div>
                        </td>
                        <td class="text-center">
                          <img
                            (click)="deleteAuditMaster(audit?.id, delete)"
                            class="cursor-pointer"
                            src="./assets/images/comment-delete.svg"
                            style="width: 16px"
                          />
                        </td>
                      </tr>
                    </tbody>
                    <tr *ngIf="!auditList?.length">
                      <td colspan="12" class="noRecords text-center mt-2">
                        <span class="mt-2"
                          >{{ staticText.common.no_records_found }}
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <ng-template #newAuditMasterPopUp>
            <div class="modal-dialog m-0">
              <div class="modal-content" style="border: none">
                <app-modal-header-common [title]="'New Audit'">
                </app-modal-header-common>

                <div
                  class="modal-body p-0 p-3 incident-tracker"
                  style="overflow-y: auto; height: 40vh"
                >
                  <form [formGroup]="auditMasterForm">
                    <div class="row">
                      <div class="col-lg-12">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Title</mat-label>
                          <input
                            type="text"
                            matInput
                            status="basic"
                            formControlName="title"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Description</mat-label>
                          <input
                            type="text"
                            matInput
                            status="basic"
                            formControlName="description"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Vendor</mat-label>
                          <mat-select formControlName="vendor">
                            <mat-option
                              *ngFor="let vendor of vendorList"
                              [value]="vendor.id"
                              style="font-size: 0.8rem; height: 30px"
                            >
                              {{ vendor.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Frequency</mat-label>
                          <input
                            type="text"
                            matInput
                            status="basic"
                            formControlName="frequency"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Last Conducted Date</mat-label>
                          <input
                            matInput
                            [matDatepicker]="picker"
                            readonly
                            [ngModelOptions]="{ standalone: true }"
                            fullWidth
                            status="basic"
                            (click)="picker.open()"
                            formControlName="last_conducted"
                          />
                          <mat-datepicker-toggle matSuffix [for]="picker">
                          </mat-datepicker-toggle>
                          <mat-datepicker #picker> </mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Next Due Date</mat-label>
                          <input
                            matInput
                            [matDatepicker]="picker1"
                            readonly
                            [ngModelOptions]="{ standalone: true }"
                            fullWidth
                            status="basic"
                            (click)="picker1.open()"
                            formControlName="next_due"
                          />
                          <mat-datepicker-toggle matSuffix [for]="picker1">
                          </mat-datepicker-toggle>
                          <mat-datepicker #picker1> </mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Focus Area</mat-label>
                          <input
                            type="text"
                            matInput
                            status="basic"
                            formControlName="focus_area"
                          />
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Agency</mat-label>
                          <input
                            type="text"
                            matInput
                            status="basic"
                            formControlName="agency"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <mat-label class="pl-2">Upload Template*</mat-label>
                    <div class="pl-2" *ngIf="!templatedoc">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="mt-1 upload">
                            <div
                              class="w-100 cursor-pointer d-flex justify-content-center"
                              (click)="fileInput.click()"
                            >
                              <span class="mt-1 ms-2 my-auto secondary">
                                {{ staticText?.common.upload_doc }}</span
                              >
                            </div>
                            <input
                              hidden
                              #fileInput
                              type="file"
                              id="file"
                              (change)="onFileSelected($event)"
                            />
                          </div>
                        </div>
                        <p class="text-dark file-status fs-14 mb-0">
                          {{ staticText?.common.doc_ext_maxsize }}
                        </p>
                      </div>
                    </div>
                    <div class="row" *ngIf="templatedoc">
                      <div class="row">
                        <div class="d-flex align-items-center">
                          <div class="d-flex">
                            <div class="mr-2 mt-2">
                              <img
                                *ngIf="
                                  templatedoc == 'doc' || templatedoc == 'docx'
                                "
                                src="./assets/images/word.svg"
                                height="40"
                              />
                              <img
                                *ngIf="templatedoc == 'pdf'"
                                src="./assets/images/pdf.svg"
                                height="40"
                              />
                              <img
                                *ngIf="
                                  templatedoc == 'xls' || templatedoc == 'xlsx'
                                "
                                src="./assets/images/excel.svg"
                                height="40"
                              />
                              <img
                                *ngIf="templatedoc == 'csv'"
                                src="./assets/images/csv.png"
                                height="40"
                              />
                              <mat-icon
                                *ngIf="
                                  templatedoc !== 'pdf' &&
                                  templatedoc !== 'doc' &&
                                  templatedoc !== 'docx' &&
                                  templatedoc !== 'xls' &&
                                  templatedoc !== 'xlsx' &&
                                  templatedoc !== 'csv'
                                "
                                mat-list-icon
                                class="material-icons my-auto fs-1 text-danger"
                              >
                                description</mat-icon
                              >
                            </div>
                            <div class="m-2 ms-0">
                              {{ templateFile }}
                              <p class="text-muted doc-subhead mb-0">
                                {{ templateSize | number : "1.2-2" }}MB
                              </p>
                            </div>
                          </div>
                          <div class="d-flex">
                            <!-- <div>
                                        <img (click)="downloadFile(i)" class="cursor-pointer px-3"
                                            src="./assets/images/download-icon-new.svg">
                                    </div> -->
                            <div>
                              <img
                                (click)="deleteFile(i, delete)"
                                class="cursor-pointer px-3"
                                src="./assets/images/delete-icon-new.svg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <div class="row">
                    <div class="d-flex justify-content-between">
                      <button
                        (click)="cancel()"
                        mat-stroked-button
                        mat-button
                        color="primary"
                        class="mx-1"
                      >
                        Close
                      </button>

                      <!-- <button (click)="saveAudit('addMore')"  mat-flat-button color="primary">Save & Add More
                        </button> -->
                      <button
                        (click)="saveAudit('')"
                        class="bg-primary text-white mx-1"
                        mat-button
                        mat-flat-button
                        color="primary"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template #delete>
            <div class="modal-dialog custom-width m-0">
              <div class="modal-content">
                <app-modal-header-common
                  [title]="staticText?.modal_popup?.confirmation_title"
                >
                </app-modal-header-common>

                <div class="modal-body">
                  <p class="fs-5 py-3 text-center primary m-0 fw-bold">
                    {{ staticText?.modal_popup?.del_msg }}
                  </p>
                </div>
                <div class="modal-footer border-0">
                  <div class="row">
                    <div class="col-md-6">
                      <button
                        mat-button
                        mat-dialog-close
                        (click)="closeDialog()"
                      >
                        {{ staticText?.modal_popup?.no_btn }}
                      </button>
                    </div>
                    <div class="col-md-6">
                      <button
                        mat-button
                        [disabled]="disableFields"
                        mat-dialog-close
                        (click)="deleteDialog()"
                        class="bg-primary text-white"
                      >
                        {{ staticText?.modal_popup?.yes_btn }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="single-right-pane">
          <span class="bubble-top" style="background-color: #ed0000"
            >&nbsp;</span
          >
          <app-gnews></app-gnews>
          <!-- <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                                style="font-size: 13px">
                                Curated from Web
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body p-2">
                                <app-loading *ngIf="loading"></app-loading>
                                <app-gnews [display_header]="'false'"></app-gnews>
                            </div>
                        </div>
                    </div>    -->
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
