import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ListTrackerComponent } from "./list-tracker/list-tracker.component";
import { AuditComponent } from "./audit/audit.component";
import { AuditLogComponent } from "./audit-log/audit-log.component";
import { IncidentTrackerTemplatesComponent } from "./incident-tracker-templates/incident-tracker-templates.component";
import { AuditInstanceComponent } from "./audit-instance/audit-instance.component";

const routes: Routes = [
  {
    path: "",
    component: ListTrackerComponent,
    data: { breadcrumb: null },
  },
  {
    path: "templates",
    data: { breadcrumb: "Templates" },
    component: IncidentTrackerTemplatesComponent,
  },
  {
    path: "instance",
    data: { breadcrumb: "Audit Instance" },
    component: AuditInstanceComponent,
  },
  {
    path: "audit",
    data: { breadcrumb: "Audit" },
    // component: AuditComponent,
    children: [
      {
        path: "",
        data: { breadcrumb: "" },
        component: AuditComponent,
      },
      {
        path: "audit-log",
        data: { breadcrumb: "Audit Log" },
        component: AuditLogComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IncidentTrackerRoutingModule {}
