<app-modal-header-common title="Edit Instance"> </app-modal-header-common>
<div class="plan-form">
  <h6>{{ instanceInfo.template.title }}</h6>
  <div class="row align-items-center mb-1">
    <div class="col-sm-3 label">Evidence File -</div>
    <div class="col-sm-9 value">
      <input
        type="file"
        (change)="this.onFileChange($event)"
        #fileInput
        hidden
      />
      <div class="d-flex align-items-center gap-2">
        <mat-icon (click)="fileInput.click()">cloud_upload</mat-icon>
        <span *ngIf="fileName">{{ fileName }}</span>
      </div>
    </div>
  </div>
  <form [formGroup]="instanceForm">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Owner</mat-label>
      <mat-select formControlName="owner">
        <ng-container *ngFor="let user of ownersList">
          <mat-option
            *ngIf="user.first_name || user?.last_name"
            [value]="user.id"
          >
            {{ user?.first_name }} {{ user?.last_name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option value="open"> Open </mat-option>
        <mat-option value="in-progress"> In Progress </mat-option>
        <mat-option value="complete"> Complete </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Comment</mat-label>
      <input matInput formControlName="comment" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Due Date</mat-label>
      <input
        matInput
        [matDatepicker]="picker1"
        readonly
        fullWidth
        status="basic"
        (click)="picker1.open()"
        formControlName="due_date"
      />
      <mat-datepicker-toggle matSuffix [for]="picker1"> </mat-datepicker-toggle>
      <mat-datepicker #picker1> </mat-datepicker>
    </mat-form-field>
    <div style="display: flex; justify-content: end" class="mt-3">
      <button
        mat-raised-button
        color="primary"
        class="templ-btn"
        (click)="editInstance()"
        [disabled]="instanceForm.invalid"
      >
        Save
      </button>
    </div>
  </form>
</div>
