<div class="w-100 bg-lavendar mt-80">
  <app-header></app-header>
</div>
<div class="bg-color p-3 pt-0">
  <div class="px-0 py-3">
    <app-bread-crumb></app-bread-crumb>
  </div>
  <div class="container-fluid pr-0 pl-0">
    <div class="row">
      <div class="col-sm-2" *ngIf="!isEmbedded">
        <div class="single-left-pane">
          <app-side-menu></app-side-menu>
        </div>
      </div>
      <div [ngClass]="{ 'col-sm-12': isEmbedded, 'col-sm-7': !isEmbedded }">
        <div *ngIf="instanceInfo" class="single-center-pane">
          <div class="row mb-1">
            <div class="col-sm-6 trn-trc">
              <h5 class="m-0" style="font-weight: 500">
                {{ instanceInfo?.template?.title }}
              </h5>
              <p style="font-size: 12px" class="m-0">
                Due Date:
                <b>{{ instanceInfo?.end_date | date : "dd MMM, yyyy" }}</b>
              </p>
              <p
                style="font-size: 12px; text-transform: capitalize"
                class="m-0"
              >
                Status: <b>{{ instanceInfo?.status }}</b>
              </p>
              <p
                style="font-size: 12px; text-transform: capitalize"
                class="m-0"
              >
                Owner:
                <b>{{
                  instanceInfo?.created_by?.first_name +
                    " " +
                    instanceInfo?.created_by?.last_name
                }}</b>
              </p>
            </div>
          </div>
          <div class="row">
            <p class="m-0 mb-1" style="font-size: 13px">{{ auditInfo }}</p>
            <app-tag
              [tagsIds]="this.instanceTags"
              [isReadOnly]="true"
            ></app-tag>
          </div>
          <hr class="my-2" />
          <mat-tab-group
            mat-align-tabs="start">
            <mat-tab label="Summary">
              <br>
          <h6 class="m-0" style="font-weight: 500; font-size: 14px">
            Respondent
          </h6>
          <div
            class="mb-1"
            style="
              font-size: 11px;
              display: flex;
              padding: 5px 0;
              overflow-x: scroll;
            "
          >
            <span
              class="usercircle"
              [matTooltip]="
                respondent?.first_name + ' ' + respondent?.last_name
              "
              >{{ respondent?.first_name.charAt(0)
              }}{{ respondent?.last_name.charAt(0) }}</span
            >
          </div>
          <h6 class="m-0" style="font-weight: 500; font-size: 14px">Auditor</h6>
          <div
            class="mb-3"
            style="
              font-size: 11px;
              display: flex;
              padding: 5px 0;
              overflow-x: scroll;
            "
          >
            <span
              class="usercircle"
              [matTooltip]="auditor?.first_name + ' ' + auditor?.last_name"
              >{{ auditor?.first_name.charAt(0)
              }}{{ auditor?.last_name.charAt(0) }}</span
            >
          </div>
          <h6>Respondent response summary -</h6>
          <div *ngIf="chartData">
            <highcharts-chart
              style="display: flex"
              [Highcharts]="Highcharts"
              [options]="chartData"
              [oneToOne]="true"
            ></highcharts-chart>
          </div>
          <h6>Observation summary -</h6>
          <div
            class="custom-table table-responsive"
            style="max-height: calc(100vh - 220px)"
          >
            <table class="table table-bordered">
              <thead style="position: sticky; z-index: 1">
                <tr>
                  <th
                    *ngFor="let field of tableColumns"
                    scope="col"
                    class="text-center"
                  >
                    {{ field }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let response of instanceInfo?.responses;
                    let i = index
                  "
                >
                  <td class="text-center">
                    {{ response.observation }}
                  </td>
                  <td>{{ response.severity }}</td>
                  <td>{{ response.impact }}</td>
                  <td>{{ response.remediation }}</td>
                  <td
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 3rem !important;
                    "
                    [matTooltip]="response.comment"
                  >
                    {{ response.comment }}
                  </td>
                  <td>
                    {{
                      response.owner__first_name +
                        " " +
                        response.owner__last_name
                    }}
                  </td>
                  <td class="text-center">
                    <mat-icon
                      *ngIf="response.evidence_metadata.length"
                      (click)="
                        downloadEvidence(
                          response.id,
                          response.evidence_metadata
                        )
                      "
                      >cloud_download</mat-icon
                    >
                    <span *ngIf="!response.evidence_metadata.length"
                      >No evidence</span
                    >
                  </td>
                  <td>{{ response.due_date }}</td>
                  <td>{{ response.status }}</td>
                  <td>
                    <div class="d-flex justify-content-between">
                      <mat-icon
                        aria-hidden="false"
                        class="cursor-pointer"
                        (click)="showEditDialog(response)"
                      >
                        edit
                      </mat-icon>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tr *ngIf="!instanceInfo?.responses?.length">
                <td colspan="10" class="noRecords text-center mt-2">
                  <span class="mt-2"
                    >{{ staticText.common.no_records_found }}
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab label="Insights">
          <app-power-bi-module page="grc_audit_insight"></app-power-bi-module>
        </mat-tab>
      </mat-tab-group>
        </div>
      </div>
      <div class="col-sm-3" *ngIf="!isEmbedded">
        <div class="single-right-pane">
          <span class="bubble-top" style="background-color: #ed0000"
            >&nbsp;</span
          >
          <app-gnews></app-gnews>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
