import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TurnkeyTrackerService } from "src/app/shared/services/turnkey-tracker.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AssessmentCentralService } from "src/app/shared/services/assessment-central.service";
import { MessageService } from "src/app/shared/services/message.service";
import { TagsService } from "src/app/shared/services/tag.service";
import { DeletePopupComponent } from "src/app/shared/components/delete-popup/delete-popup.component";
import { FilterService } from "src/app/shared/services/filter.service";
import { TemplateCreateModalDialogComponent } from "../template-create-modal/template-create-modal";
import { IncidentTrackerAssessmentCreateModalDialogComponent } from "./incident-tracker-assessment-create-modal/incident-tracker-assessment-create-modal";
import { IncidentTrackerService } from "src/app/shared/services/incident-tracker.service";

@Component({
  selector: "assessment-preview-modal",
  templateUrl: "assessment-preview-modal.html",
  styleUrls: ["assessment-preview-modal.scss"],
})
export class AssessmentPreviewModalDialogComponent implements AfterViewInit {
  template: any;
  questions: any = [];

  constructor(
    public dialogRef: MatDialogRef<AssessmentPreviewModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private incidentTrackerService: IncidentTrackerService,
    private spinner: NgxSpinnerService
  ) {}

  ngAfterViewInit() {
    this.template = this.data.dataKey;
    this.template.questions.forEach((element: any) => {
      let domainIndex = this.questions.findIndex(
        (e: any) => e.domain === element.domain
      );
      if (domainIndex > -1) {
        let subdomainIndex = this.questions[domainIndex].subdomains.findIndex(
          (s: any) => s.sub_domain === element.sub_domain
        );
        if (subdomainIndex > -1) {
          this.questions[domainIndex].subdomains[subdomainIndex].questions.push(
            element
          );
        } else {
          this.questions[domainIndex].subdomains.push({
            sub_domain: element.sub_domain,
            questions: [element],
          });
        }
      } else {
        this.questions.push({
          domain: element.domain,
          subdomains: [
            {
              sub_domain: element.sub_domain,
              questions: [element],
            },
          ],
        });
      }
    });
  }

  download() {
    this.incidentTrackerService
      .downloadResponseTemplate(this.data.dataKey.id)
      .subscribe(
        (res: any) => {
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(res);
          downloadLink.setAttribute("download", this.data.dataKey.file_name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        (error: any) => {
          this.spinner.hide();
          throw error;
        }
      );
  }

  closeModal(bool: boolean = false): void {
    this.dialogRef.close(bool);
  }
}

@Component({
  selector: "app-incident-tracker-templates",
  templateUrl: "./incident-tracker-templates.component.html",
  styleUrls: ["./incident-tracker-templates.component.scss"],
})
export class IncidentTrackerTemplatesComponent implements OnInit, OnDestroy {
  templates: any = [];
  filteredTemplate: any = [];
  file: any;
  searchText: string = "";
  ownersList: any = [];
  filterType: any = null;
  filterName: any = null;
  filterApplicability: any = null;
  filterOwner: any = null;
  readMode: any = false;
  subscription: any;
  filterObj: any;
  tagFilterId: any;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private assessmentCentralService: AssessmentCentralService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private toastrService: ToastrService,
    private tagService: TagsService,
    private route: ActivatedRoute,
    private filterService: FilterService,
    private incidentTrackerService: IncidentTrackerService
  ) {}

  ngOnInit(): void {
    this.getTemplates();
    this.getOwners();
    this.messageService.setGnews("Assessment Central - Templates");
    this.route.queryParamMap.subscribe((params) => {
      this.readMode = params.get("readMode") === "1";
    });
    this.subscription = this.filterService.filterSubject.subscribe(
      (res: any) => {
        this.filterObj = res;
        this.tagFilterId = [];
        Object.keys(this.filterObj).forEach((element: any) => {
          if (element == "tag") {
            console.log(this.filterObj[element]);
            this.filterObj[element]?.array?.forEach((tag: any) => {
              this.tagFilterId.push(tag?.id);
            });
          }
        });
        this.search();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getOwners() {
    this.assessmentCentralService.getOwnersList().subscribe((resp) => {
      this.ownersList = resp;
    });
  }

  getTemplates() {
    this.incidentTrackerService.getAuditTemplates().subscribe((res: any) => {
      res.forEach((category: any) => {
        category.templates.forEach((element: any) => {
          element.color = this.getGMapRandomColor();
        });
      });
      this.templates = res;
      this.filteredTemplate = res;
    });
  }

  templateDownload() {
    this.incidentTrackerService.downloadTemplate().subscribe(
      (res: any) => {
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(res);
        const fileName = "sample_template";
        downloadLink.setAttribute("download", fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
      (error: any) => {
        this.spinner.hide();
        throw error;
      }
    );
  }

  previewAssessment(template: any) {
    const dialog = this.dialog.open(AssessmentPreviewModalDialogComponent, {
      width: "50vw",
      data: {
        dataKey: template,
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (!!data) {
        this.proceed(template);
      }
    });
  }

  proceed(template: any) {
    const dialog = this.dialog.open(
      IncidentTrackerAssessmentCreateModalDialogComponent,
      {
        width: "500px",
        data: { template_id: template.id, template_name: template.title },
      }
    );
    dialog.afterClosed().subscribe((data) => {
      if (!!data) {
      }
    });
  }

  async addTags(id: any, selectedTags: any) {
    if (selectedTags?.length > 0) {
      await selectedTags.forEach(async (element: any) => {
        let pst = {
          entity_id: id,
          entity_type: "grc_audit_template",
          tag_id: element.id,
        };
        await this.tagService.magTag(pst).subscribe((res: any) => {});
      });
    }
  }

  onFilechange(event: any) {
    this.file = event.target.files[0];
    const dialog = this.dialog.open(TemplateCreateModalDialogComponent, {
      width: "500px",
      data: {
        uploadedFile: this.file,
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("file_name", this.file.name);
        formData.append("title", data.title);
        formData.append("description", data.description);
        formData.append("applicability", data.applicability);
        formData.append("focus_area", data.focus_area);
        formData.append("is_external_assessment", data.is_external_assessment);
        formData.append("internal_assessor", data.internal_assessor);
        formData.append("category", data.category);
        formData.append("frequency", data.frequency);
        formData.append("type", data.type);
        formData.append("external_assessor", data.external_assessor);
        this.incidentTrackerService
          .uploadFile(formData)
          .subscribe((response: any) => {
            this.spinner.hide();
            if (response) {
              this.file = [];
              this.addTags(response.id, data.selectedTags);
              this.previewAssessment(response);
              this.getTemplates();
            }
          });
      }
      event.target.value = "";
    });
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  search() {
    const tags = this.tagFilterId ? this.tagFilterId.join("|") : "";
    const type = this.filterType ? this.filterType : "";
    const name = this.filterName ? this.filterName : "";
    const applicability = this.filterApplicability
      ? this.filterApplicability
      : "";
    const owner = this.filterOwner ? this.filterOwner : "";
    this.incidentTrackerService
      .getFilteredAuditTemplates(type, name, applicability, tags, owner)
      .subscribe((res: any) => {
        res.forEach((category: any) => {
          category.templates.forEach((element: any) => {
            element.color = this.getGMapRandomColor();
          });
        });
        this.templates = res;
        this.filteredTemplate = res;
      });
  }

  deleteTemplate(id: any, name: string) {
    let dialogRef = this.dialog.open(DeletePopupComponent, {
      data: name,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.assessmentCentralService
          .deleteTemplate(id)
          .subscribe((res: any) => {
            this.toastrService.success("Templated deleted successfully !");
            this.getTemplates();
          });
      }
    });
  }

  blankPlan() {
    this.proceed(null);
  }

  navigateToIncidentTracker() {
    this.router.navigateByUrl("incidentracker");
  }
}
