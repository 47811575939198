<div class="w-100 bg-lavendar mt-80">
  <app-header></app-header>
</div>
<div class="bg-color p-3 pt-0">
  <div class="px-0 py-3">
    <app-bread-crumb></app-bread-crumb>
  </div>
  <div class="container-fluid pr-0 pl-0">
    <div class="row">
      <div class="col-sm-2">
        <div class="single-left-pane">
          <app-side-menu></app-side-menu>
        </div>
      </div>
      <div class="col-sm-7">
        <div class="single-center-pane">
          <div
            class="d-flex mb-2"
            style="align-items: center; justify-content: space-between"
          >
            <h5>GRC Workbench</h5>
            <div class="justify-content-end">
              <div *ngIf="selectedTabIndex === 0">
                <button
                  mat-button
                  class="bg-primary text-white mr-2"
                  mat-button
                  mat-flat-button
                  (click)="navigate(1)"
                >
                  Audit Library
                </button>
                <button
                  mat-button
                  class="bg-primary text-white"
                  mat-button
                  mat-flat-button
                  (click)="navigate()"
                >
                  <mat-icon
                    aria-hidden="false"
                    aria-label="material-icons-filled "
                    class="cursor-pointer fs-5"
                    >add
                  </mat-icon>
                  Create Audit
                </button>
              </div>
              <button
                *ngIf="selectedTabIndex === 2"
                mat-button
                class="bg-primary text-white"
                mat-button
                mat-flat-button
                (click)="onClickNewIncident(newIncidentPopUp)"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="material-icons-filled "
                  class="cursor-pointer fs-5"
                  >add
                </mat-icon>
                New Incident
              </button>
            </div>
          </div>
          <app-loading *ngIf="!dataLoaded"></app-loading>
          <div *ngIf="dataLoaded">
            <div class="filter-container mb-2 mt-3">
              <div class="filter-icon">
                <img
                  height="25px"
                  width="25px"
                  src="./assets/images/filter-cp.png"
                />
              </div>
              <input
                type="text"
                placeholder="Search Type..."
                class="form-control text-input"
                [(ngModel)]="filterType"
                (ngModelChange)="search()"
                *ngIf="selectedTabIndex === 0"
                style="
                  min-width: 13rem;
                  border-radius: 8px;
                  font-size: 12px;
                  height: 40px;
                "
              />
              <input
                type="text"
                placeholder="Search Title..."
                class="form-control text-input"
                [(ngModel)]="filterTitle"
                (ngModelChange)="search()"
                *ngIf="selectedTabIndex === 1"
                style="
                  min-width: 13rem;
                  border-radius: 8px;
                  font-size: 12px;
                  height: 40px;
                "
              />
              <input
                type="text"
                placeholder="Search Name..."
                class="form-control text-input"
                [(ngModel)]="filterName"
                (ngModelChange)="search()"
                *ngIf="selectedTabIndex === 0"
                style="
                  min-width: 13rem;
                  border-radius: 8px;
                  font-size: 12px;
                  height: 40px;
                "
              />
              <app-filter-card
                *ngIf="selectedTabIndex === 0"
                [pagetype]="'assessment-template'"
              ></app-filter-card>
              <mat-form-field
                class="w-100 filter-dropdown"
                appearance="outline"
                style="font-size: 12px; margin-left: -10px"
                *ngIf="selectedTabIndex === 0"
              >
                <mat-select
                  [(ngModel)]="filterStatus"
                  (ngModelChange)="search()"
                  placeholder="Search Status..."
                >
                  <mat-option value=""></mat-option>
                  <mat-option value="pending">Pending</mat-option>
                  <mat-option value="In-progress">In-Progress</mat-option>
                  <mat-option value="completed">Completed</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="w-100 filter-dropdown"
                appearance="outline"
                style="font-size: 12px"
              >
                <mat-select
                  [(ngModel)]="filterPortfolio"
                  (ngModelChange)="search()"
                  placeholder="Search Portfolio..."
                >
                  <mat-option value=""></mat-option>
                  <mat-option
                    *ngFor="let portfolio of portfolioList"
                    value="{{ portfolio.id }}"
                    >{{ portfolio.display_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="w-100 filter-dropdown"
                appearance="outline"
                style="font-size: 12px"
              >
                <mat-select
                  [(ngModel)]="filterSubportfolio"
                  (ngModelChange)="search()"
                  placeholder="Search Subportfolio..."
                >
                  <mat-option value=""></mat-option>
                  <mat-option
                    *ngFor="let subportfolio of subportfolioList"
                    value="{{ subportfolio.id }}"
                    >{{ subportfolio.display_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="w-100 filter-dropdown"
                appearance="outline"
                style="font-size: 12px"
              >
                <mat-select
                  [(ngModel)]="filterProgram"
                  (ngModelChange)="search()"
                  placeholder="Search Program..."
                >
                  <mat-option value=""></mat-option>
                  <mat-option
                    *ngFor="let program of programList"
                    value="{{ program.id }}"
                    >{{ program.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="w-100 filter-dropdown"
                appearance="outline"
                style="font-size: 12px"
              >
                <mat-select
                  [(ngModel)]="filterProject"
                  (ngModelChange)="search()"
                  placeholder="Search Project..."
                >
                  <mat-option value=""></mat-option>
                  <mat-option
                    *ngFor="let project of projectList"
                    value="{{ project.id }}"
                    >{{ project.project_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="w-100 filter-dropdown"
                appearance="outline"
                style="font-size: 12px"
              >
                <mat-select
                  [(ngModel)]="filterVendor"
                  (ngModelChange)="search()"
                  placeholder="Search Vendor..."
                >
                  <mat-option value=""></mat-option>
                  <mat-option
                    *ngFor="let vendor of vendorList"
                    value="{{ vendor.id }}"
                    >{{ vendor.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <mat-tab-group
              mat-align-tabs="start"
              [(selectedIndex)]="selectedTabIndex"
              (selectedTabChange)="tabChanged($event)"
            >
              <mat-tab label="Audits">
                <div style="overflow-x: hidden">
                  <div class="tab-holder mt-3">
                    <div class="accordion" id="accordionPanel">
                      <div
                        *ngFor="let category of auditInstances; let i = index"
                        class="accordion-item"
                      >
                        <h2 class="accordion-header" id="heading{{ i }}">
                          <button
                            class="accordion-button {{
                              i === 0 ? '' : 'collapsed'
                            }}"
                            type="button"
                            data-bs-toggle="collapse"
                            attr.data-bs-target="#collapse{{ i }}"
                            aria-expanded="{{ i == 0 ? 'true' : 'false' }}"
                            aria-controls="collapse{{ i }}"
                            style="font-size: 13px; font-weight: 700"
                          >
                            {{ category.type }}
                          </button>
                        </h2>
                        <div
                          id="collapse{{ i }}"
                          class="accordion-collapse collapse {{
                            i === 0 ? 'show' : ''
                          }}"
                          aria-labelledby="heading{{ i }}"
                          data-bs-parent="#accordionPanel"
                        >
                          <div class="accordion-body p-2">
                            <div class="flex-container-1">
                              <div
                                *ngIf="
                                  category.instances &&
                                  category.instances?.length <= 0
                                "
                                class="trn-no"
                              >
                                No records found.
                              </div>
                              <app-loading
                                style="width: 100%; min-height: 200px"
                                *ngIf="!category.instances"
                              ></app-loading>
                              <div
                                class="flex-item p-1"
                                *ngFor="let x of category.instances"
                              >
                                <div
                                  class="trn-x"
                                  [ngStyle]="{ background: x.color }"
                                  style="height: 200px"
                                  (click)="openInstance(x)"
                                >
                                  <h6 class="mb-0 trn-nm">
                                    {{ x.template.title }}
                                  </h6>
                                  <div
                                    class="mb-0 mt-2 trn-ph"
                                    *ngIf="x.created_by"
                                  >
                                    <div class="d-flex align-items-center">
                                      <mat-icon class="small-icon mr-1"
                                        >perm_identity</mat-icon
                                      >
                                      <span>Owner</span>
                                    </div>
                                    <span
                                      style="display: flex; font-weight: 700"
                                    >
                                      {{
                                        x.created_by.first_name +
                                          " " +
                                          x.created_by.last_name
                                      }}
                                    </span>
                                  </div>
                                  <p
                                    class="mb-0 mt-2 trn-ph"
                                    *ngIf="x.end_date"
                                  >
                                    Created On
                                    <span
                                      style="display: flex; font-weight: 700"
                                    >
                                      {{ x.start_date | date : "dd MMM, yyyy" }}
                                    </span>
                                  </p>
                                  <p
                                    class="mb-0 mt-2 trn-ph"
                                    *ngIf="x.end_date"
                                  >
                                    Due By
                                    <span
                                      style="display: flex; font-weight: 700"
                                    >
                                      {{ x.end_date | date : "dd MMM, yyyy" }}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="auditInstances?.length === 0"
                      class="text-center"
                    >
                      No records found
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Audits - Insights">
                <app-power-bi-module
                  page="grc_audit_insight"
                ></app-power-bi-module>
              </mat-tab>
              <mat-tab label="Incidents">
                <div style="overflow-x: hidden">
                  <div class="tab-holder mt-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="custom-height">
                          <div
                            class="custom-table table-responsive"
                            style="max-height: calc(100vh - 220px)"
                          >
                            <table class="table table-bordered">
                              <thead style="position: sticky; z-index: 1">
                                <tr>
                                  <th
                                    *ngFor="
                                      let field of staticText.incident_tracker
                                        .table_header_incident_tracker
                                    "
                                    scope="col"
                                    class="text-center"
                                  >
                                    {{ field }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngFor="
                                    let inc of incidentList;
                                    let i = index
                                  "
                                >
                                  <tr>
                                    <td class="text-center">{{ i + 1 }}</td>
                                    <td>{{ inc?.title }}</td>
                                    <td>{{ inc?.type?.display_name }}</td>
                                    <td>{{ inc?.severity?.display_name }}</td>
                                    <td>{{ inc?.vendor?.name }}</td>
                                    <td>{{ inc?.reported_date }}</td>
                                    <td>{{ inc?.closure_date }}</td>
                                    <td class="text-center pt-1 pb-1">
                                      <div
                                        class="d-flex justify-content-between"
                                      >
                                        <mat-icon
                                          aria-hidden="false"
                                          class="cursor-pointer fs-2"
                                          (click)="
                                            editIncident(inc, newIncidentPopUp)
                                          "
                                        >
                                          edit_note
                                        </mat-icon>
                                        <img
                                          (click)="
                                            deleteIncident(inc?.id, delete)
                                          "
                                          class="cursor-pointer"
                                          src="./assets/images/comment-delete.svg"
                                          style="width: 16px"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                              <tr *ngIf="!incidentList?.length">
                                <td
                                  colspan="10"
                                  class="noRecords text-center mt-2"
                                >
                                  <span class="mt-2"
                                    >{{ staticText.common.no_records_found }}
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Incidents - Insights">
                <app-power-bi-module
                  page="grc_incident_insight"
                ></app-power-bi-module>
              </mat-tab>
            </mat-tab-group>
          </div>

          <ng-template #newIncidentPopUp>
            <div class="modal-dialog m-0" style="max-width: 100%">
              <div class="modal-content" style="border: none">
                <app-modal-header-common [title]="incidentValue">
                </app-modal-header-common>

                <div
                  class="modal-body p-0 p-3 incident-tracker"
                  style="overflow-y: auto; height: 40vh"
                >
                  <form [formGroup]="incidentTrackerForm">
                    <div class="row">
                      <div class="col-lg-4">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Vendor</mat-label>
                          <mat-select formControlName="vendor_id">
                            <mat-option
                              *ngFor="let vendor of vendorList"
                              [value]="vendor.id"
                              style="font-size: 0.8rem; height: 30px"
                            >
                              {{ vendor.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-4">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Project</mat-label>
                          <mat-select formControlName="project_id">
                            <mat-option
                              *ngFor="let project of projectList"
                              [value]="project.id"
                              style="font-size: 0.8rem; height: 30px"
                            >
                              {{ project.project_name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-4">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Program</mat-label>
                          <mat-select formControlName="program_id">
                            <mat-option
                              *ngFor="let program of programList"
                              [value]="program.id"
                              style="font-size: 0.8rem; height: 30px"
                            >
                              {{ program.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Type</mat-label>
                          <mat-select formControlName="type_id">
                            <mat-option
                              *ngFor="let type of incidentType"
                              [value]="type.id"
                              >{{ type.display_name }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Audit Instance</mat-label>
                          <mat-select formControlName="audit_instance_id">
                            <mat-option
                              value="audit"
                              *ngFor="
                                let instanceTitle of auditInstanceTitleList
                              "
                              [value]="instanceTitle.value"
                            >
                              {{ instanceTitle.display_name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Title</mat-label>
                          <input
                            type="text"
                            matInput
                            status="basic"
                            formControlName="title"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Description</mat-label>
                          <input
                            type="text"
                            matInput
                            status="basic"
                            formControlName="description"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Severity</mat-label>
                          <mat-select formControlName="severity_id">
                            <mat-option
                              *ngFor="let severity of incidentSeverityList"
                              [value]="severity.id"
                              >{{ severity.display_name }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-4">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Domain</mat-label>
                          <!-- <input matInput type="text" status="basic"  formControlName="Status"> -->
                          <mat-select
                            formControlName="domain_id"
                            (selectionChange)="onSelectionChange($event)"
                          >
                            <mat-option
                              *ngFor="let domain of domainList"
                              [value]="domain.id"
                              style="font-size: 0.8rem; height: 30px"
                            >
                              {{ domain.display_name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-4">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Sub Domain</mat-label>
                          <mat-select formControlName="subdomain_id">
                            <mat-option
                              *ngFor="let subdomain of subDomainSelectedList"
                              [value]="subdomain.id"
                              style="font-size: 0.8rem; height: 30px"
                            >
                              {{ subdomain.display_name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Portfolio</mat-label>
                          <mat-select formControlName="portfolio_id">
                            <mat-option
                              *ngFor="let portfolio of portfolioList"
                              value="{{ portfolio.id }}"
                              >{{ portfolio.display_name }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Subportfolio</mat-label>
                          <mat-select formControlName="sub_portfolio_id">
                            <mat-option
                              *ngFor="let subportfolio of subportfolioList"
                              value="{{ subportfolio.id }}"
                              >{{ subportfolio.display_name }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Impact</mat-label>
                          <input
                            type="text"
                            matInput
                            status="basic"
                            formControlName="impact"
                          />
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Source</mat-label>
                          <input
                            type="text"
                            matInput
                            status="basic"
                            formControlName="source"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Status</mat-label>
                          <mat-select formControlName="status_id">
                            <mat-option
                              *ngFor="let status of incidentStatusList"
                              [value]="status.id"
                              >{{ status.display_name }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Reported By</mat-label>
                          <mat-select formControlName="raised_by">
                            <ng-container *ngFor="let user of teamList">
                              <mat-option
                                *ngIf="user.first_name || user?.last_name"
                                [value]="user.id"
                                style="font-size: 0.8rem; height: 30px"
                              >
                                {{ user?.first_name }} {{ user?.last_name }}
                              </mat-option>
                            </ng-container>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Corrective Plan</mat-label>
                          <input
                            type="text"
                            matInput
                            status="basic"
                            formControlName="corrective_plan"
                          />
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Preventive Plan</mat-label>
                          <input
                            type="text"
                            matInput
                            status="basic"
                            formControlName="preventive_plan"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Primary Owner</mat-label>
                          <mat-select formControlName="primary_owner">
                            <ng-container *ngFor="let user of teamList">
                              <mat-option
                                *ngIf="user.first_name || user?.last_name"
                                [value]="user.id"
                                style="font-size: 0.8rem; height: 30px"
                              >
                                {{ user?.first_name }} {{ user?.last_name }}
                              </mat-option>
                            </ng-container>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Secondary Owner</mat-label>
                          <mat-select formControlName="secondary_owner">
                            <ng-container *ngFor="let user of teamList">
                              <mat-option
                                *ngIf="user.first_name || user?.last_name"
                                [value]="user.id"
                                style="font-size: 0.8rem; height: 30px"
                              >
                                {{ user?.first_name }} {{ user?.last_name }}
                              </mat-option>
                            </ng-container>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Reported Date</mat-label>
                          <input
                            matInput
                            [matDatepicker]="picker"
                            readonly
                            [ngModelOptions]="{ standalone: true }"
                            fullWidth
                            status="basic"
                            (click)="picker.open()"
                            formControlName="reported_date"
                          />
                          <mat-datepicker-toggle matSuffix [for]="picker">
                          </mat-datepicker-toggle>
                          <mat-datepicker #picker> </mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Closure Date</mat-label>
                          <input
                            matInput
                            [matDatepicker]="picker1"
                            readonly
                            [ngModelOptions]="{ standalone: true }"
                            fullWidth
                            status="basic"
                            (click)="picker1.open()"
                            formControlName="closure_date"
                          />
                          <mat-datepicker-toggle matSuffix [for]="picker1">
                          </mat-datepicker-toggle>
                          <mat-datepicker #picker1> </mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <mat-form-field
                          class="col-12 px-2"
                          appearance="outline"
                        >
                          <mat-label>Comment</mat-label>
                          <input
                            type="text"
                            matInput
                            status="basic"
                            formControlName="comment"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <div class="row">
                    <div class="d-flex justify-content-between">
                      <!-- <button (click)="saveTracker('addMore')"  mat-flat-button color="primary">Save & Add More
                        </button> -->
                      <button
                        (click)="cancel()"
                        mat-stroked-button
                        mat-button
                        color="primary"
                        class="mx-1"
                      >
                        Close
                      </button>
                      <button
                        *ngIf="editIncidentId"
                        (click)="updateTracker('')"
                        class="bg-primary text-white mx-1"
                        mat-button
                        mat-flat-button
                        color="primary"
                      >
                        Update
                      </button>
                      <button
                        *ngIf="!editIncidentId"
                        (click)="saveTracker('')"
                        class="bg-primary text-white mx-1"
                        mat-button
                        mat-flat-button
                        color="primary"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template #delete>
            <div class="modal-dialog custom-width m-0">
              <div class="modal-content">
                <app-modal-header-common
                  [title]="staticText?.modal_popup?.confirmation_title"
                >
                </app-modal-header-common>

                <div class="modal-body">
                  <p class="fs-5 py-3 text-center primary m-0 fw-bold">
                    {{ staticText?.modal_popup?.del_msg }}
                  </p>
                </div>
                <div class="modal-footer border-0">
                  <div class="row">
                    <div class="col-md-6">
                      <button
                        mat-button
                        mat-dialog-close
                        (click)="closeDialog()"
                      >
                        {{ staticText?.modal_popup?.no_btn }}
                      </button>
                    </div>
                    <div class="col-md-6">
                      <button
                        mat-button
                        [disabled]="disableFields"
                        mat-dialog-close
                        (click)="deleteDialog()"
                        class="bg-primary text-white"
                      >
                        {{ staticText?.modal_popup?.yes_btn }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="single-right-pane">
          <div>
            <div class="accordion" id="accordionExample">
              <div *ngIf="selectedTabIndex === 0" class="accordion-item">
                <h2 class="accordion-header" id="heading1">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#insights-collapse"
                    aria-expanded="true"
                    aria-controls="insights-collapse"
                    style="font-size: 13px"
                  >
                    Insights
                  </button>
                </h2>
                <div
                  id="insights-collapse"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading1"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body p-2" style="font-size: 13px">
                    <p>
                      Completed Audits:
                      {{ auditInsights?.status_counts[0]?.count }}
                    </p>
                    <p>
                      Audits in progress:
                      {{ auditInsights?.status_counts[1]?.count }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                    style="font-size: 13px"
                  >
                    Curated from Web
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body p-2">
                    <app-gnews
                      [pagename]="'Balanced Scorecard'"
                      [display_header]="'false'"
                    ></app-gnews>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
