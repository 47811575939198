<app-modal-header-common [title]="template?.name"> </app-modal-header-common>
<div style="padding: 20px; font-size: 13px">
  <div *ngIf="template" style="height: 60vh; overflow: scroll">
    <mat-accordion>
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        [expanded]="isFirst"
        *ngFor="let domain of questions; first as isFirst"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> {{domain.domain}} </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- Sub-Accordion for Subdomains -->
        <div style="padding: 10px !important">
          <mat-accordion>
            <mat-expansion-panel *ngFor="let subdomain of domain.subdomains">
              <mat-expansion-panel-header>
                <mat-panel-title> {{subdomain.sub_domain}} </mat-panel-title>
              </mat-expansion-panel-header>
              <div
                *ngFor="let data of subdomain.questions; let i = index"
                class="q mb-2 m-2"
              >
                <span>{{i + 1}}</span>
                <h6 class="m-0"><b>{{data.requirement}}</b></h6>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="d-flex gap-1 align-items-center">
    <div>
      Please download the template and update responses before uploading it in
      the next page -
    </div>
    <div class="mt-1" (click)="download()">
      <mat-icon>cloud_download</mat-icon> &nbsp;
    </div>
  </div>
  <div style="padding: 10px; display: flex; justify-content: right">
    <button
      mat-flat-button
      color="primary"
      (click)="closeModal(true)"
      style="width: auto"
    >
      Proceed
    </button>
  </div>
</div>
