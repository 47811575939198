import { Component, OnInit } from '@angular/core';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { IncidentTrackerService } from 'src/app/shared/services/incident-tracker.service';
import { FormBuilder, FormGroup,FormArray,Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  providers: [DatePipe],
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  auditLogList:any;
  auditLogForm: FormGroup | any;
  teamList:any;
  auditList:any;
  templateFile:any;
  templateSize:any;
  deleteAuditLogId:any;
  templatedoc:any;
  constructor(
    private IncidentTrackerService :IncidentTrackerService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private datepipe: DatePipe,
    private spinner: NgxSpinnerService,
  ) { 
    this.auditLogForm = this.fb.group({
      date:['',Validators.required],
      owner:['',Validators.required],
      audit_report:['',Validators.required],
      audit:['',Validators.required]
    });
  }

  ngOnInit(): void {
    this.getAuditLogList();
    this.getAuditList();
    this.getUserList();
  }

  async getAuditLogList(){
    return await this.IncidentTrackerService.getAuditLogList().subscribe((res: any) => {
      this.auditLogList = res;
    });
  }

  async getAuditList(){
    return await this.IncidentTrackerService.getAuditList().subscribe((res: any) => {
      this.auditList = res;
    });
  }
  async getUserList(){
    return await this.IncidentTrackerService.getOwner().subscribe((res: any) => {
      this.teamList = res;
    });
  }
  onClickNewAuditLog(modal:any){
    this.dialog.open(modal, {
      width: 'auto',
      minWidth: '500px',
      height: '58vh',
    });
  }

  deleteFile(i: any, content: any) {
    this.templatedoc = '';
    this.templateSize = '';
    this.templateFile = '';
    this.auditLogForm.get('audit_report').setValue('');
  }

  onFileSelected(e:any){
    const inputElement = e.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      if (inputElement.files[0].size > 5000000) {
        this.toastrService.error('Please upload less than 5MB');
        return;
      }
      this.auditLogForm.get('audit_report').setValue(file);
      this.templateFile = inputElement.files[0].name;
      this.templateSize= inputElement.files[0].size / 1000000;
      const ext = inputElement.files[0].name.substring(inputElement.files[0].name.lastIndexOf('.') + 1)
      this.templatedoc = ext?.toLowerCase()
    }
  }
  emptyFile() {
    this.templatedoc = '';
    this.templateSize = '';
    this.templateFile = '';
    this.auditLogForm.get('audit_report').setValue('');
  }
  saveAuditLog(type:any){
    this.auditLogForm.markAllAsTouched();
    if (this.auditLogForm.invalid ) {
       return;
    }
    this.auditLogForm.value.date = this.datepipe.transform(this.auditLogForm.value.date,"yyyy-MM-dd");
    const formData = new FormData();
    formData.append('date',  this.auditLogForm.value.date);
    formData.append('owner', this.auditLogForm.get('owner').value);
    formData.append('file', this.auditLogForm.get('audit_report').value);
    formData.append('audit_id', this.auditLogForm.get('audit').value);
    
    this.spinner.show();
    this.IncidentTrackerService.postAuditLogList(formData).subscribe((res: any) => {
      this.toastrService.success('Audit log created successfully');
      this.auditLogList = res;
      this.auditList = [];
      this.auditList = res;
      this.dialog.closeAll();
      this.emptyFile();
      this.auditLogForm.reset();

      this.spinner.hide();;

    });
  }
  cancel(){
    this.dialog.closeAll();
  }

  
  downloadAuditReport(id:any,fileName:any){
    this.spinner.show();
    this.IncidentTrackerService.downloadAuditLogFile(id).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }

  deleteAuditLog(i: any, content: any) {
    this.deleteAuditLogId = i;
    this.dialog.open(content)
  }

  deleteDialog() {
    this.spinner.show();
    this.IncidentTrackerService.deleteAuditLogById(this.deleteAuditLogId).subscribe((res: any) => {
      this.toastrService.success('Audit log deleted successfully');
      this.getAuditLogList();
      this.dialog.closeAll();
      this.spinner.hide();
    }, error => {
      throw error;
    })
  }
}
